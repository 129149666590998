/* jshint ignore:start */
;
(function($) {

    $.fn.Gridder = function(options) {

        var gridder = $('<div class="gridder-show"></div>');
        var gridderclose = $('<div class="gridder-close"></div>');
        var gridderprev = $('<div class="gridder-nav prev hidden-xs"><div></div></div>');
        var griddernext = $('<div class="gridder-nav next hidden-xs"><div></div></div>');

        gridder.append(gridderclose, gridderprev, griddernext);

        return this.each(function() {

            $('.gridder-list').click(function(e) {
                e.preventDefault();

                $('.gridder-show .padding').remove();
                // $('.gridder-show .content').remove();
                // $('.gridder-show .image').remove();
                // $('.black-overlay').show();

                var currentcontent = $(this).find('.gridder-content').html();
                // var currentimage = $(this).find('.gridder-thumb').html();

                if ($(this).next().hasClass('gridder-show')) {
                    
                    gridder.toggle();

                    if ($(this).hasClass('imactive')) {
                        $(this).removeClass('imactive');
                    } else {
                        $(this).addClass('imactive');
                    }

                    // $('.gridder-show').append("<div class=image>" + currentimage + "</div>");
                    $('.gridder-show').append('<div class="content">' + currentcontent + '</div>');
                    

                } else {

                    /* Adds the Expander bloc*/
                    mybloc = gridder.insertAfter(this).css('display', 'block');

                    // $('.gridder-show').append('<div class=image>' + currentimage + '</div>');
                    $('.gridder-show').append("<div class=content>" + currentcontent + "</div>");

                    /* Make sure the correct bloc is active*/
                    if (!$(this).hasClass('imactive')) {
                        $('.imactive').removeClass('imactive');
                        $(this).addClass("imactive");
                    }

                    

                }

                /* Scrolls to the current row */
                $('.gridder-list').click(function() {
	                $('html, body').animate({
	                    scrollTop: $(this).offset().top
	                }, 3000);
				});

            });

            /* Close */
            $('.gridder').on('click', '.gridder-close', function() {
                $('.imactive').removeClass('imactive');
                $('.gridder-show .content').remove();
                $('.gridder-show .image').remove();
                $('.gridder-show').remove();
                $('.black-overlay').hide();
            });
            
            $(document).keydown(function(e){
			    if (e.keyCode == 27) {
		                $('.gridder-close').trigger('click');
			       return false;
			    }
			});

            /* Next */
   //          $('.gridder').on('click', '.gridder-nav.next', function() {
   //              $(this).parent().next().trigger('click');
   //          });
   //          $(document).keydown(function(e){
			//     if (e.keyCode == 39) {
		 //                $('.gridder-nav.next').trigger('click');
			//        return false;
			//     }
			// });

            /* Previous */
   //          $('.gridder').on('click', '.gridder-nav.prev', function() {
   //              $(this).parent().prev().prev().trigger('click');
   //          });
   //          $(document).keydown(function(e){
			//     if (e.keyCode == 37) {
		 //                $('.gridder-nav.prev').trigger('click');
			//        return false;
			//     }
			// });

        });
    };
})(jQuery);