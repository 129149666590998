/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {

        const locations = [
          ["Blueprint Coffee – Delmar", 38.655497, -90.300260, "<div class='info_window'><p><a href='http://goo.gl/maps/JoAuZ' target='_blank'>6225 Delmar Blvd<br/>Get Directions</a></p></div>"],
          ["Blueprint Coffee – Watson", 38.593608, -90.301286, "<div class='info_window'><p><a href='https://goo.gl/SNbAAJ' target='_blank'>4206 Watson Rd<br/>Get Directions</a></p></div>"],
          ["Blueprint Coffee – High Low", 38.6378356, -90.2285764, "<div class='info_window'><p><a href='https://goo.gl/maps/NkF5Ayugr9hLXHpa9' target='_blank'>3301 Washington Blvd<br/>Get Directions</a></p></div>"]
        ];

        function initMap() {
          const map = new google.maps.Map(document.getElementById("map-canvas"), {
            center: { lat: 38.655497, lng: -90.300260 },
            zoom: 11,
            scrollwheel: false,
            panControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            zoomControl: false,
            scaleControl: false,
            navigationControl: false,
            mapTypeId : google.maps.MapTypeId.ROADMAP,
            styles: [
              {
                "stylers": [
                  { "lightness": 46 },
                  { "gamma": 0.49 },
                  { "hue": "#000000" },
                  { "saturation": -100 }
                ]
              },{
                "featureType": "transit",
                "stylers": [
                  { "visibility": "off" }
                ]
              },{
                "featureType": "poi",
                "stylers": [
                  { "visibility": "off" }
                ]
              }
            ]
          });

          function setMarkers(map) {
            var iconUrl = "/app/themes/blueprintcoffeev3/dist/images/marker-blueprint@2x.png";
            var bpIcon = new google.maps.MarkerImage( iconUrl, null, null, null, new google.maps.Size(52,57));

            for (var l = 0; l < locations.length; l++) {

              var location = locations[l];

              new google.maps.Marker({
                position: {
                  lat: location[1],
                  lng: location[2]
                },
                map: map,
                icon: bpIcon,
                title: location[0],
                infoWindow: {
                  maxWidth: 200,
                  content: location[3]
                }
              });

              new google.maps.InfoWindow({
                content: location[3]
              });
              
              /* jshint ignore:start */
              google.maps.event.addListener(location, 'click', function() {
                location.info.open(map, this);
              });
              /* jshint ignore:end */
            
            }
            // map.fitBounds(bounds);
          }

          setMarkers(map);
        }

        initMap();
        // end new map
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // WooCommerce Checkout
    'woocommerce_checkout': {
      init: function() {

        var billing_autocomplete = new google.maps.places.Autocomplete($('#billing_address_1')[0]);
        var shipping_autocomplete = new google.maps.places.Autocomplete($('#shipping_address_1')[0]);

        billing_autocomplete.setFields(['address_components']);
        shipping_autocomplete.setFields(['address_components']);

        billing_autocomplete.addListener('place_changed', function() {

          var billingPlace = billing_autocomplete.getPlace();

          if (billingPlace.address_components) {

            var components = billingPlace.address_components;

            var billing_street_number = null;
            var billing_street_name = null;
            var billing_city = null;
            // var billing_state = null;
            var billing_state_short = null;
            var billing_postcode = null;
            // var billing_country = null;
            var billing_country_short = null;

            for (var i = 0, component; component = components[i]; i++) { // jshint ignore:line
              // console.log(component);
              if (component.types[0] === 'street_number') {
                billing_street_number = component.long_name;
              }
              if (component.types[0] === 'route') {
                billing_street_name = component.short_name;
              }
              if (component.types[0] === 'locality') {
                billing_city = component.long_name;
              }
              if (component.types[0] === 'administrative_area_level_1') {
                // billing_state = component.long_name;
                billing_state_short = component.short_name;
              }
              if (component.types[0] === 'postal_code') {
                billing_postcode = component.long_name;
              }
              if (component.types[0] === 'country') {
                // billing_country = component.long_name;
                billing_country_short = component.short_name;
              }
            }

            $('#billing_address_1').val(billing_street_number + ' ' + billing_street_name);
            $('#billing_city').val(billing_city);
            $('#billing_state').val(billing_state_short);
            $('#billing_state').trigger('change.select2');
            $('#billing_postcode').val(billing_postcode);
            $('#billing_country').val(billing_country_short);
            $('#billing_country').trigger('change.select2');

          }

        });

        shipping_autocomplete.addListener('place_changed', function() {

          var shippingPlace = shipping_autocomplete.getPlace();

          if (shippingPlace.address_components) {

            var components = shippingPlace.address_components;

            var shipping_street_number = null;
            var shipping_street_name = null;
            var shipping_city = null;
            // var shipping_state = null;
            var shipping_state_short = null;
            var shipping_postcode = null;
            // var shipping_country = null;
            var shipping_country_short = null;

            for (var i = 0, component; component = components[i]; i++) { // jshint ignore:line
              // console.log(component);
              if (component.types[0] === 'street_number') {
                shipping_street_number = component.long_name;
              }
              if (component.types[0] === 'route') {
                shipping_street_name = component.short_name;
              }
              if (component.types[0] === 'locality') {
                shipping_city = component.long_name;
              }
              if (component.types[0] === 'administrative_area_level_1') {
                // shipping_state = component.long_name;
                shipping_state_short = component.short_name;
              }
              if (component.types[0] === 'postal_code') {
                shipping_postcode = component.long_name;
              }
              if (component.types[0] === 'country') {
                // shipping_country = component.long_name;
                shipping_country_short = component.short_name;
              }
            }

            $('#shipping_address_1').val(shipping_street_number + ' ' + shipping_street_name);
            $('#shipping_city').val(shipping_city);
            $('#shipping_state').val(shipping_state_short);
            $('#shipping_state').trigger('change.select2');
            $('#shipping_postcode').val(shipping_postcode);
            $('#shipping_country').val(shipping_country_short);
            $('#shipping_country').trigger('change.select2');

          }

        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
